import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { Box, Button, Tab, Tabs, useMediaQuery, useTheme, IconButton, Alert, Grid } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  CheckSquare as CheckSquareIcon,
  Edit as EditIcon,
  Eye as EyeIcon
} from 'react-feather';
import { showAlert as showGlobalAlert, clearAllAlerts as clearAllGlobalAlerts } from '../store/actions/alertActions';
import { hide, show } from '../store/actions/busyIndicatorActions';
import { FormQuestionMediaReq, FormQuestionMultiChoiceReq, FormQuestionNumericReq, FormQuestionSignatureReq, FormQuestionSingleChoiceReq, FormQuestionTextualReq, FormQuestionType, FormReq, FormRes, RegularExpressionRes } from '../proxy/proxy';
import FormsService from '../services/FormsService';
import FormPreview from './FormPreview';
import FormSubmissions from './FormSubmissions';
import TabPanel from '../components/TabPanel';
import { formatErrorMessage, formatSuccessMessage, isDriver } from '../utils/common';
import ConfirmButton from '../components/ConfirmButton';
import LocalStorageService from '../services/LocalStorageService';

interface FormElement {
  id: number;
  questionType: number;
  pageNumber: number;
  title: string;
  subtitle?: string;
  regularExpression?: string;
  required: boolean;
  formQuestionNumericRestrictionType?: number;
  restrictionInput1?: number;
  restrictionInput2?: number;
  choices?: string[];
  minimumFilesLimit?: number;
  maximumFilesLimit?: number;
  isMultiline?: boolean;
  // options?: Option[];
  // value: any;
}

interface Props {
  showAlert: (message: any) => any,
  clearAllAlerts: () => any,
  showBusyIndicator: () => any,
  hideBusyIndicator: () => any,
}

const FormSubmission: React.FC<Props> = ({
  showAlert,
  clearAllAlerts,
  showBusyIndicator,
  hideBusyIndicator
}) => {
  const navigate = useNavigate();

  const { formId } = useParams();
  const { submissionId } = useParams();
  const existingFormId = !Number.isNaN(Number(formId)) ? Number(formId) : 0;
  const existingSubmissionId = !Number.isNaN(Number(submissionId)) ? Number(submissionId) : 0;

  const [formState, setFormState] = useState<FormRes>();
  const [activeTab, setActiveTab] = useState(0);

  type FormQuestionTypes = FormQuestionTextualReq | FormQuestionNumericReq | FormQuestionMultiChoiceReq | FormQuestionSingleChoiceReq | FormQuestionMediaReq | FormQuestionSignatureReq;
  const [fieldStates, setFieldStates] = useState<FormQuestionTypes[]>([]);

  const refreshData = () => {
    if (!Number.isNaN(existingFormId) && existingFormId > 0) {
      FormsService.getFormById(Number(existingFormId))
        .then((result) => {
          setFormState(result);
          if (!result?.isActive) {
            setActiveTab(1);
          }
          setFieldStates(result?.formQuestions?.map((element) => JSON.parse(JSON.stringify(element))) || []);
        }).catch((err) => {
          // showAlert(formatErrorMessage(JSON.parse(err.response)));
        })
        .then(() => {
          hideBusyIndicator();
        });
    }

    showBusyIndicator();
  };

  useEffect(() => {
    clearAllAlerts();
    refreshData();
  }, []);

  const handleTabChange = (event: any, newValue: any) => {
    console.log(newValue);
    setActiveTab(newValue);
  };

  const handleEdit = () => {
    navigate(`/app/forms/builder/${formId}`);
  };

  const localStorageService = LocalStorageService.getService();
  const roles = localStorageService.getUserRoles();
  const isUserDriver = isDriver(roles);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Fragment>
      <Box
        sx={{
          position: 'sticky',
          top: '0',
          left: '0',
          zIndex: '100',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, // Change direction based on screen size
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'white',
          borderBottom: 1,
          borderColor: 'divider',
          height: { xs: 'auto', sm: '80px' }, // Auto height on mobile, fixed on larger screens
          px: 2, // Padding on the x-axis
          py: { xs: 0, sm: 0 }, // Padding on the y-axis (top/bottom) for mobile
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            mb: { xs: 1, sm: 0 }, // Margin bottom on mobile
          }}
        >
          <Tabs value={activeTab} onChange={handleTabChange} aria-label="form editor tabs">
            <Tab value={0} icon={<AssignmentIcon />} iconPosition="start" label="Form" />
            <Tab value={1} icon={<AssignmentTurnedInIcon />} iconPosition="start" label="Submissions" />
          </Tabs>
        </Box>

        {/* Show Edit and Delete buttons only if the user is not a driver */}
        {!isUserDriver && (
          <Box sx={{ display: 'flex', gap: 1, marginLeft: 'auto' }}>
            {isMobile ? (
              <IconButton
                color="primary"
                onClick={handleEdit}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<EditIcon />}
                onClick={handleEdit}
              >
                Edit
              </Button>
            )}
          </Box>
        )}
      </Box>
      <TabPanel value={activeTab} index={0} style={{ position: 'relative' }}>
        {!(formState?.isActive ?? true) && (
          <Grid container spacing={1} direction="row" justifyContent="center">
            <Grid item md={6} sx={{ maxWidth: '100%' }} sm={12}>
              <Alert severity="error" style={{ marginBottom: '16px', textAlign: 'center' }}>
                {`Form is unpublished. ${isUserDriver ? 'Please contact your manager to publish the form.' : 'Please publish it to submit it.'}`}
              </Alert>
            </Grid>
          </Grid>
        )}
        {(!formState?.isActive ?? true) && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
              zIndex: 10, // Ensure overlay is above the content
            }}
          />
        )}
        <div
          style={{
            pointerEvents: (formState?.isActive ?? true) ? 'auto' : 'none', // Prevent interaction
            opacity: (formState?.isActive ?? true) ? 1 : 0.5,
            cursor: (formState?.isActive ?? true) ? 'default' : 'not-allowed', // Change cursor for visual feedback
          }}
        >
          <FormPreview
            key={formState?.formType}
            currentFormState={{
              ...formState,
              companyId: 0,
            }}
            currentFieldStates={fieldStates}
            submissionId={existingSubmissionId}
          />
        </div>
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <FormSubmissions
          key={formState?.name}
          existingFormId={existingFormId}
        />
      </TabPanel>
    </Fragment>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch: any) => ({
  showBusyIndicator: () => dispatch(show()),
  hideBusyIndicator: () => dispatch(hide()),
  showAlert: (message: any) => dispatch(showGlobalAlert(message)),
  clearAllAlerts: () => dispatch(clearAllGlobalAlerts())
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSubmission);
